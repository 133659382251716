import React from 'react'
import '../App.css';
import menu01 from '../img/menu01.jpg';


const Home = () => {
  return (
    <div className='main'>

     <div className='menu'>  
    <div className='title'>
      <p>ReflyU International Limited</p>
    </div>
    <div className='menu_01'>
    <span className='menu_01_text'>
Warm The World!<br/><br/>
Passion<br/>
Creative<br/>
Service<br/>

      </span>
  
    </div>
</div>
    </div>
  )
}

export default Home
